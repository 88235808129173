import React from 'react';
import store from './state/store';
import { Provider } from 'react-redux';
import { Route, Routes } from 'react-router-dom';

import './App.css';

import Home from './pages/Home';
import Main from './pages/Main';
import Practice from './pages/Practice';
import Pathways from './pages/Pathways';
import Perspectivies from './pages/Perspectivies';
import Partners from './pages/Partners';
import AboutUs from './pages/AboutUs';
import Internship from './pages/Internship'
import InternForm from './pages/Internship/components/form';
import MailingList from './pages/MailingList';
import PracticeArea from './pages/Practice/components/PracticeArea';
import People from './pages/Partners/components/associates/People';
import Post from './components/ClientUpdate/components/Post';
import QualifiedLawyers from './pages/qualifiedLawyers';
import QualifiedLawyersForm from './pages/qualifiedLawyers/components/form';
import Search from './pages/Search';

function App() {
  return (
    <Provider store={store}>
    <div>
      <Routes>
        <Route path='/' element={<Main />}></Route>
        <Route path='/home' element={<Home />}></Route>
        <Route path='/about-us' element={<AboutUs />}></Route>
        <Route path='/capabilities' element={<Practice />}></Route>
        <Route path='/capabilities/practice-area/:category' element={<PracticeArea />}></Route>
        <Route path='/people' element={<Partners />}></Route>
        <Route path='/people/:id' element={<People />}></Route>
        <Route path='/news-and-insights' element={<Perspectivies />}></Route>
        <Route path='/news-and-insights/post/:id' element={<Post/>}></Route>
        <Route path='/career' element={<Pathways />}></Route>
        <Route path='/career/internship' element={<Internship />}></Route>
        <Route path='/career/associate' element={<QualifiedLawyers />}></Route>
        <Route path='/internship-application' element={<InternForm />}></Route>
        <Route path='/associate-application' element={<QualifiedLawyersForm />}></Route>
        <Route path='/search' element={<Search />}></Route>
        <Route path='/mailing-list' element={<MailingList />}></Route>
      </Routes>
    </div>
    </Provider>
  );
}

export default App;
