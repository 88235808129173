import axios from "axios";

export const getAuth = () => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_BASE_URL}/api/auth/local`,
            data: {
                identifier: process.env.REACT_APP_USER,
                password: process.env.REACT_APP_USER_PASSWORD
            }
        })
        .then(response => {
            resolve(response.data.jwt);
        })
        .catch(err => {
            reject('Failed to Get Capabilities Page!')
        })
    })
}