import React from 'react'
import ReactPaginate from 'react-paginate'
import { MdOutlineKeyboardArrowLeft } from 'react-icons/md'
import { MdOutlineKeyboardArrowRight } from 'react-icons/md'

const Pagination = ({ totalPosts, postsPerPage, setCurrentPage }) => {
    const pageCount = Math.ceil(totalPosts / postsPerPage)
    const changePage = ({ selected }) => {
        setCurrentPage(selected + 1)
    }
    return (
        <div className='desktop:text-[23px] tablet:text-[23px] mobile:text-[14px] half:text-[20px] med:text-[13px] flex items-center justify-center dark:text-white'>
            <ReactPaginate
            breakLabel='...'
            previousLabel={<MdOutlineKeyboardArrowLeft size={50} className='desktop:text-[23px] tablet:text-[23px] mobile:text-[14px] half:text-[20px] med:text-[13px] text-[#32475C] dark:text-white dark:hover:bg-white dark:hover:bg-opacity-[20%] dark:hover:text-black opacity-[87%] hover:bg-[#32475C] hover:bg-opacity-[4%] rounded-full p-[0.8rem]' />}
            nextLabel={<MdOutlineKeyboardArrowRight size={50} className='desktop:text-[23px] tablet:text-[23px] mobile:text-[14px] half:text-[20px] med:text-[13px] text-[#32475C] dark:text-white dark:hover:bg-white dark:hover:bg-opacity-[20%] dark:hover:text-black opacity-[87%] hover:bg-[#32475C] hover:bg-opacity-[4%] rounded-full p-[0.8rem]' />}
            pageRangeDisplayed={3}
            pageCount={pageCount}
            onPageChange={changePage}
            renderOnZeroPageCount={null}
            containerClassName='desktop:text-[23px] tablet:text-[23px] mobile:text-[14px] half:text-[20px] med:text-[13px] paginationBttns flex items-center justify-center dark:text-black'
            pageLinkClassName='desktop:text-[23px] tablet:text-[23px] mobile:text-[14px] half:text-[20px] med:text-[13px] paginationA dark:text-white dark:hover:bg-white dark:hover:bg-opacity-[20%] dark:hover:text-black hover:bg-[#32475C] hover:bg-opacity-[10%] rounded-full p-[0.8rem]'
            activeClassName='desktop:text-[23px] tablet:text-[23px] mobile:text-[14px] half:text-[20px] med:text-[13px] paginationActive dark:text-black dark:bg-white dark:bg-opacity-[20%]'
            
        />
        </div>
    )
}
    

export default Pagination