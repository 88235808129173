import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getQualifiedLawyersInfo } from '../../apiService/careerPage';

import NavbarHome from '../../components/NavbarHome';
import SubFooter from '../../components/SubFooter';

const QualifiedLawyers = () => {
    const [data, setData] = useState({});
    const language = useSelector(state => state.language)

    useEffect(() => {
        window.scrollTo(0,0)
        getQualifiedLawyersInfo(language)
        .then(res => {
            setData(res);
        })
      }, [language])

    return (
        <>
        <NavbarHome />
        <section className='bg-light dark:bg-dark px-[5vw] py-[10vh]'>
        <div className='flex flex-row justify-between tablet:pt-[5vh] '>
            <div className='flex flex-col'>
                    {/* <h3 className='flex flex-row text-[1.1vw] text-[#123872]'><a href='/home'>HOME</a><BsArrowRightShort className='mx-[0.5vw] mt-[0.5vh]'/><a href='/pathways'>PATHWAYS</a><BsArrowRightShort className='mx-[0.5vw] mt-[0.5vh]'/>INTERNSHIP</h3> */}
                <ul className=''>
                    <h1 className='caption-home1 text-[#4493f1]'>{data?.title}</h1>
                </ul>
            </div>
        </div>
        <div className='flex flex-row justify-center'>
        
        </div>
        <div className=''>
            <h2 className='font-mulish tablet:text-[18px] mobile:text-[14px] tablet:leading-[30px] mobile:leading-[25px] font-semibold tracking-wide text-justify text-justify tablet:pb-[3vh] mobile:pb-[1vh] text-[#4493f1]'>{data?.topic}</h2>
            <div className=''>
                { data && data?.paragraph?.length && data?.paragraph?.map((item, index) => index === 0
                    ? <p key={index} className='font-mulish dark:text-white tablet:text-[18px] mobile:text-[14px] tablet:leading-[30px] mobile:leading-[25px] font-normal tracking-wide text-justify text-justify pb-[3vh] text-[#4493f1]'>{item}</p>
                    : <p key={index} className='font-mulish dark:text-white tablet:text-[18px] mobile:text-[14px] tablet:leading-[30px] mobile:leading-[25px] font-normal tracking-wide text-justify text-justify pb-[3vh]'>{item}</p>
                )}
            </div>

            <a href='/associate-application' className=''>
                <button className='bg-transparent border-[1px] mt-[2vh] text-[#4493f1] border-[#4493f1] px-[2vw] py-[1vh] tablet:text-[14px] half:text-[12px] mobile:text-[12px] font-mulish font-medium hover:bg-[#4493f1] hover:text-white cursor-pointer rounded'>APPLY</button>
            </a>
        </div>
    </section>
    <SubFooter/>
        </>
        
    );
}

export default QualifiedLawyers;