import React from 'react'

const CardPathways = ({ title, description, onClick }) => {
  return (
    <div className="rounded-2xl tablet:w-[44vw] half:w-full mobile:w-full desktop:h-[30vh] tablet:h-[35vh] med:h-[33vh] half:h-[32vh] mobile:h-[32vh] tablet:mb-[5vh] mobile:mb-[3vh]">
      <div className="flex flex-col place-content-between h-full bg-[#4493f1] dark:bg-black rounded-xl tablet:px-[2vw] mobile:px-[4vw] tablet:py-[3vh] mobile:py-[5vh]">
        <div className='text-start text-white m-0 w-[100%] overflow-hidden '>
          <h3 className='font-mulish tablet:text-[22px] med:text-[16px] mobile:text-[26px] tablet:leading-[30px] mobile:leading-[25px] font-normal tablet:mb-[3vh] half:mb-[2vh] med:mb-[3vh] mobile:mb-[1vh] tracking-wide'>{title}</h3>
          <p className='font-mulish tablet:text-[18px] med:text-[16px] mobile:text-[14px] tablet:leading-[30px] mobile:leading-[25px] font-normaltablet:w-[80%] mobile:w-[100%]'>{description}</p>  
      </div>
      <div className=''>
          <button onClick={onClick} className='bg-transparent border-[1px] border-white px-[2vw] py-[1vh] tablet:text-[14px] half:text-[12px] mobile:text-[12px] font-mulish font-medium text-white hover:bg-white hover:text-[#4493f1] dark:hover:text-black cursor-pointer rounded uppercase'>APPLY</button>
      </div>

     </div>
</div>
  )
}

export default CardPathways