import styled from 'styled-components';

const CapabilitiesCard = ({
    data = {},
    language = 'id',
    onChange,
}) => {

    const Div = styled.div`
        &:hover {
            background-image: url(${process.env.REACT_APP_BASE_URL}${data?.thumbnail?.data?.attributes?.formats?.medium?.url});
        }
    `;

    const sortDesc = (a, b) => {
        if (language === 'en' ? a.attributes.name < b.attributes.name : a.attributes.name_bahasa < b.attributes.name_bahasa) {
            return -1
        }
        if (language === 'en' ? a.attributes.name > b.attributes.name : a.attributes.name_bahasa > b.attributes.name_bahasa) {
            return 1
        }
        return 0
    }

    return (

        <div id='corporate-finance-investment' className='mb-[8vh]'>
            <Div className={`capabilities-one w-full h-full]`}>
                <div className='flex flex-col w-[100%] h-max items-center dark:hover:bg-black hover:bg-white hover:bg-opacity-50 dark:hover:hover:bg-opacity-50 px-[8vw] pt-[8vh]'>
                    <h1 className='font mulish dark:text-white desktop:text-[32px] tablet:text-[32px] half:text-[32px] med:text-[32px] mobile:text-[20px] tablet:leading-[60px] mobile:leading-[30px] tracking-[2%]'>{language == 'en' ? data.name : data.name_bahasa}</h1>
                    <p className='font-mulish dark:text-white desktop:text-[18px] tablet:text-[18px] half:text-[16px] med:text-[16px] mobile:text-[12px] tablet:leading-[30px] mobile:leading-[20px] text-justify py-[6vh]'>{language == 'en' ? data.description : data.description_bahasa}</p>
                    <div className='flex items-center justify-center pb-[10vh] tablet:w-[23vw] half:w-[30vw] mobile:w-[45vw] description'>
                        <select 
                            className="select-css font-mulish text-white desktop:text-[16px] tablet:text-[14px] mobile:text-[12px] half:text-[12px] med:text-[12px] rounded-lg focus:ring-[#4493f1] focus:border-[#4493f1] block w-full px-2.5"
                            onChange={onChange}
                        >
                            <option className='bg-white dark:bg-black text-black dark:text-white border-transparent' selected>{language === 'en' ? 'Practice Areas' : 'Area Praktik'}</option>
                            { data?.practice_areas?.data?.length && data?.practice_areas?.data?.sort(sortDesc).map((item, index) => (
                                <option key={index} value={item.attributes.name} className='bg-white dark:bg-black text-black dark:text-white border-transparent'>{language === 'en' ? item.attributes.name : item.attributes.name_bahasa}</option>
                            ))}
                        </select>
                    </div>
                </div>
            </Div>
        </div>
    )
}

export default CapabilitiesCard;