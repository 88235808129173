import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";

import { getSearch } from '../../apiService/homePage'

import NavbarHome from '../../components/NavbarHome';
import SubFooter from '../../components/SubFooter';

const Search = () => {
    const searchResult = useLocation().state
    const language = useSelector(state => state.language)
    
    const [allData, setAllData] = useState({});
    const [searchData, setSearchData] = useState('');

    useEffect(() => {
        window.scrollTo(0,0)
        if(searchData) {
            getSearch(searchData)
            .then(res => {
                setAllData(res)
            })
        } else {
            getSearch(searchResult)
            .then(res => {
                setAllData(res)
            })
        }
      }, [searchData])
      
    return (
        <>
        <NavbarHome setSearchData={setSearchData} />
        <section className='bg-light dark:bg-dark pt-[15vh] pb-[30vh] px-[5vw]'>
            <div className='flex flex-col '>
                <div className='flex flex-col dark:text-white'>
                    <h1 className='caption-home1 text-[#4493f1]'>{language == 'en' ? 'Search Result' : 'Hasil Pencarian'} "{searchData || searchResult}"</h1>
                    {/* Posts */}
                    <p className='font-mulish tablet:text-[18px] mobile:text-[14px] tablet:leading-[30px] mobile:leading-[25px] tracking-wide text-justify mb-[3vh] font-bold'>Posts</p>
                    { allData?.posts?.map((item, index) => (
                        <a key={index} href={`/news-and-insights/post/${item.id}`}>
                            <div>
                                <p className='font-mulish tablet:text-[18px] mobile:text-[14px] tablet:leading-[30px] mobile:leading-[25px] font-normal tracking-wide text-justify mb-[3vh] hover:underline'>{item.title}</p>
                            </div>                            
                        </a>
                    )) }

                    {/* People */}
                    <p className='font-mulish tablet:text-[18px] mobile:text-[14px] tablet:leading-[30px] mobile:leading-[25px] tracking-wide text-justify mb-[3vh] font-bold'>People</p>
                    { allData?.people?.map((item, index) => (
                        <a key={index} href={`/people/${item.id}`}>
                            <div>
                                <p className='font-mulish tablet:text-[18px] mobile:text-[14px] tablet:leading-[30px] mobile:leading-[25px] font-normal tracking-wide text-justify mb-[3vh] hover:underline'>{item.title}</p>
                            </div>                            
                        </a>
                    )) }
                </div>
        </div>
    </section>
    <SubFooter/>
        </>
        
    );
}

export default Search;