import axios from "axios";

const getMainPage = () => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'GET',
            url: `${process.env.REACT_APP_BASE_URL}/api/pages`,
            params: {
                'filters[name][$containsi]': 'intro',
                populate: '*'
            }
        })
        .then(res => {
            const data = {
                title: res.data.data[0].attributes.one_liner,
                cta: res.data.data[0].attributes.call_to_action,
                image: `${process.env.REACT_APP_BASE_URL}${res.data.data[0].attributes.media.data[0].attributes.formats.large.url}`
            };
            resolve(data)
        })
        .catch(err => {
            reject('Failed to Get Main Page!')
        })
    })
}

export default getMainPage;