import axios from "axios";

export const getCapabilitiesPage = (token) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'GET',
            url: `${process.env.REACT_APP_BASE_URL}/api/business-categories?sort=name%3Aasc`,
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                'filters[is_active][$eq]': 'True',
                populate: '*'
            }
        })
        .then(res => {
            resolve(res.data.data);
        })
        .catch(err => {
            reject('Failed to Get Capabilities Page!')
        })
    })
}

export const getBusinessInfo = (business, token, language = 'id') => {
    return new Promise((resolve, reject) => {
        const practiceArea = business?.split('/')[3]?.replaceAll('%20', ' ')

        axios({
            method: 'GET',
            url: `${process.env.REACT_APP_BASE_URL}/api/posts`,
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                // '[filters][practice_areas][name][$eq]': business,
                '[filters][practice_areas][name][$containsi]': practiceArea,
                'populate[practice_areas][populate][partners][populate]': '*'
            }
        })
        .then(res => {
            const newContent = language === 'en'
                ? res.data.data[0]?.attributes?.content?.split('\n\n').map(item => item)
                : res.data.data[0]?.attributes?.content_bahasa?.split('\n\n').map(item => item)

            const data = {
                texts: newContent,
                title: language === 'en' ? res.data.data[0]?.attributes.title : res.data.data[0]?.attributes.title_bahasa,
                partners: res.data.data[0]?.attributes?.practice_areas?.data[0]?.attributes?.partners?.data
            }

            resolve(data);
        })
        .catch(err => {
            reject('Failed to Get Business Info!')
        })
    })
}