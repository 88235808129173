import React, { useState } from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const Achievement = ({ data }) => {
    
    const settings = {
        dots: true,
        dotsClass: "slick-dots dark:slick-dots-dark dark:text-white",
        className: "center",
        infinite: true,
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: true,
      };
      
    const [hoveredIndex, setHoveredIndex] = useState(null);
    
    function renderDescription(index) {
        if (index === hoveredIndex) {
            return (
            <div className="">
                <div className="flex flex-col items-center">           
                    <p className="font-mulish tablet:text-[18px] half:text-[14px] mobile:text-[12px] font-normal tracking-wide tablet:w-max mobile:w-[80vw] text-black dark:text-white text-center">{data[index].title}</p>
                </div>
            </div>
            )
        }
        return null;
      }
      

    return (
        <section className="w-[95vw] tablet:h-[45vh] mobile:h-[35vh] px-[5vw]">
            <Slider {...settings}>
                {
                    data.map((item, index) => {
                        return (
                            <div key={index} >
                            <div className="w-[100%] tablet:h-[30vh] mobile:h-[20vh] half:h-[30vh] med:h-[30vh]">
                                <div className="flex flex-col items-center box2 justify-center w-[12vw] h-full">
                                <div className="">
                                    <img 
                                    onMouseOver={() => setHoveredIndex(index)}
                                    onMouseLeave={() => setHoveredIndex(null)}
                                    src={item.media} alt={item.title} className=''/>
                                    
                                </div>
                            </div>
                            
                         </div>
                        </div>   
                        )
                    })
                }
            </Slider>
            <div>
                {
                    data.map((item, index) => {
                        return (
                            <div key={index} >
                                {renderDescription(index)}
                            </div>
                        )
                    })
                }
            </div>
        </section>
    )
}

export default Achievement