import axios from "axios";

export const getHomePage = async (language = 'id') => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'GET',
            url: `${process.env.REACT_APP_BASE_URL}/api/landing-page`,
            params: {}
        })
        .then(res => {
            const carousel = res.data[0].posts
            .filter(item => item.categories.length)
            .map(item => ({
                id: item.id,
                image: item.media && `${process.env.REACT_APP_BASE_URL}${item.media[0].formats?.large?.url}`,
                title: item.categories[0].name,
                description: language == 'en' ? item.title : item.title_bahasa,
            }));
            
            const achievements = res.data[0].achievements.map(item => ({
                title: item.title,
                media: `${process.env.REACT_APP_BASE_URL}${item.media.url}`
            }))

            const data = {
                carousel,
                achievements,
            };

            resolve(data)
        })
        .catch(err => {
            reject('Failed to Get Home Page!')
        })
    })
}

export const getSearch = (search) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'GET',
            url: `${process.env.REACT_APP_BASE_URL}/api/search`,
            params: {
                search
            }
        })
        .then(res => {
            const posts = []
            const people = []
            
            res.data.map(item => {
                if(item.data == "posts" && (item.category == "Client Updates" || item.category == "Published Articles")) posts.push(item)
                if(item.data == "partners") people.push(item)
            });

            const data = {
                posts,
                people
            }

            resolve(data)
        })
        .catch(err => {
            reject('Failed to Get Search Page!')
        })
    })
}

export const getHomeCapabilitiesPage = async (language = 'id') => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'GET',
            url: `${process.env.REACT_APP_BASE_URL}/api/pages?filters[name][$containsi]=capabilities`,
            params: {}
        })
        .then(res => {
            resolve(language == 'en' ? res.data.data[0]?.attributes?.one_liner : res.data.data[0]?.attributes?.one_liner_bahasa)
        })
        .catch(err => {
            reject('Failed to Get Home Page!')
        })
    })
}