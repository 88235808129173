import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import service from '../../assets/images/service.png';
import trusted from '../../assets/images/trusted.png';
import adaptive from '../../assets/images/adaptive.png';
import synergy from '../../assets/images/synergy.png';

import NavbarHome from '../../components/NavbarHome';
import SubFooter from '../../components/SubFooter';
// API
import getAboutUsPage from '../../apiService/about-usPage';

const AboutUs = (props) => {
    const language = useSelector(state => state.language)

    const[data, setData] = useState({
        title: '',
        cta: '',
        image: '',
        post: {}
    });
    
    useEffect(() => {
        window.scrollTo(0,0)
        getAboutUsPage(language)
        .then(res => {
            setData({
                title: res.title,
                cta: res.cta,
                image: res.image,
                post: res.post
            })
        })
        .catch(err => {})
    }, [language]);
      
    return (
        <>
        <NavbarHome />
        <section className='bg-light dark:bg-dark tablet:pt-[10vh] mobile:pt-[3vh] pb-[30vh] px-[5vw] '>
            <div className='flex justify-center items-center py-[2vh]'>
                <img src={data?.image} className='w-[100vw]'></img>
            </div>
            <div className='flex flex-col'>
                <div className='flex flex-col'>
                    <h1 className='caption-home1 text-[#4493f1] uppercase'>{data?.post?.title || 'About Us'}</h1>
                    
                    <p className='font-mulish dark:text-white tablet:text-[18px] mobile:text-[14px] tablet:leading-[30px] mobile:leading-[20px] font-normal tracking-wide text-justify mb-[3vh]'>{data?.post?.content}</p>
                </div>
                
                <div className='flex flex-col'>
                    <h1 className='caption-home1 tablet:pb-[5vh] mobile:pb-[2vh] dark:text-white'>
                        { language === 'en' ? 'Our Vision' : 'Visi Kami'}
                    </h1>
                    <p className='font-mulish dark:text-white tablet:text-[18px] mobile:text-[14px] tablet:leading-[30px] mobile:leading-[20px] font-normal tracking-wide text-justify mb-[3vh]'>
                        {language === 'en' ? 'To be the “go-to” counsel for all your business’s legal needs' : 'Untuk menjadi penasihat “masuk” untuk semua kebutuhan hukum bisnis Anda'}
                    </p>
                </div>
                <div className='flex flex-col'>
                    <h1 className='caption-home1 tablet:pb-[5vh] mobile:pb-[2vh] dark:text-white'>
                        { language === 'en' ? 'Our Mission' : 'Misi Kami'}
                    </h1>
                    <ul className='ml-[2vw] font-mulish dark:text-white tablet:text-[18px] mobile:text-[14px] tablet:leading-[30px] mobile:leading-[20px] font-normal tracking-wide text-justify'>
                        <li className='mb-[1vh]' type='disc'>
                            {language === 'en' ? 'To listen to and understand our clients’ legal and commercial concerns and provide innovative and responsive solutions based on our extensive Indonesian experience.' : 'Untuk mendengarkan dan memahami masalah hukum dan komersial klien kami dan memberikan solusi inovatif dan responsif berdasarkan pengalaman kami yang luas di Indonesia.'}
                        </li>
                        <li className='mb-[1vh]' type='disc'>
                            { language === 'en' ? 'To deliver top-notch legal services.' : 'Untuk memberikan layanan hukum terbaik.'}
                        </li>
                        <li className='mb-[1vh]' type='disc'>
                            { language === 'en' ? 'To provide timely, creative and highly skilled legal representation to our clients.' : 'Untuk memberikan perwakilan hukum yang tepat waktu, kreatif dan sangat terampil kepada klien kami.'}
                        </li>
                        <li className='mb-[1vh]' type='disc'>
                            { language === 'en' ? 'To build and maintain a positive, healthy and safe environment for all our stakeholders.' : 'Membangun dan memelihara lingkungan yang positif, sehat dan aman bagi seluruh pemangku kepentingan kami.'}
                        </li>
                    </ul>
                </div>
                {/* Our Value */}
                <div className='flex flex-col'>
                    <h1 className='caption-home1 dark:text-white'>
                        { language === 'en' ? 'Our Value' : 'Nilai Kami'}
                    </h1>
                    <div className='grid tablet:grid-cols-4 half:grid-cols-2 mobile:grid-cols-2 grid-flow-row gap-4 justify-between'>
                        <div className='flex flex-col items-center pt-[3vh] mr-[3vw] bg-value dark:bg-black dark:text-white tablet:w-[20vw] mobile:w-[36vw] rounded px-[3vw] pb-[1vh]'>
                            <img src={service} alt='our value' className='tablet:w-[5vw] desktop:w-[5vw] half:w-[8vw] med:w-[7vw] mobile:w-[6vw] mt-[2vh]'></img>
                            <h1 className='text-center description1 pb-[3vh] mt-[2vh]'>
                                { language === 'en' ? 'Service' : 'Melayani'}
                            </h1>
                            <p className='text-center font-mulish dark:opacity-[70%] tablet:text-[18px] mobile:text-[14px] tablet:leading-[30px] mobile:leading-[20px] font-normal tracking-wide text-center mb-[2vh]'>
                                { language === 'en' ? 'Commitment to provide excellent service in a professional manner.' : 'Komitmen untuk memberikan pelayanan prima secara profesional.'}
                            </p>
                        </div>
                        <div className='flex flex-col items-center pt-[3vh] mr-[3vw] bg-value dark:bg-black dark:text-white tablet:w-[20vw] mobile:w-[36vw] rounded px-[3vw] pb-[1vh]'>
                            <img src={trusted} alt='our value' className='tablet:w-[5vw] desktop:w-[5vw] half:w-[8vw] med:w-[7vw] mobile:w-[6vw] mt-[2vh]'></img>
                            <h1 className='text-center description1 text-center pb-[3vh] mt-[2vh]'>
                                { language === 'en' ? 'Trusted' : 'Terpercaya'}
                            </h1>
                            <p className='text-center font-mulish dark:opacity-[70%] tablet:text-[18px] mobile:text-[14px] tablet:leading-[30px] mobile:leading-[20px] font-normal tracking-wide text-center mb-[2vh]'>
                                { language === 'en' ? 'Integrity, honesty, and dependability.' : 'Integritas, kejujuran, dan ketergantungan.'}
                            </p>
                        </div>
                        <div className='flex flex-col items-center pt-[3vh] mr-[3vw] bg-value dark:bg-black dark:text-white tablet:w-[20vw] mobile:w-[36vw] rounded px-[3vw] pb-[1vh]'>
                            <img src={adaptive} alt='our value' className='tablet:w-[5vw] desktop:w-[5vw] half:w-[8vw] med:w-[7vw] mobile:w-[6vw] mt-[2vh]'></img>
                            <h1 className='text-center description1 text-center pb-[3vh] mt-[2vh]'>
                                { language === 'en' ? 'Adaptive' : 'Adaptif'}
                            </h1>
                            <p className='text-center font-mulish dark:opacity-[70%] tablet:text-[18px] mobile:text-[14px] tablet:leading-[30px] mobile:leading-[20px] font-normal tracking-wide text-center mb-[2vh]'>
                                { language === 'en' ? 'Persistent, proactive, dynamic, and enthusiastic.' : 'Gigih, proaktif, dinamis, dan antusias.'}
                            </p>
                        </div>
                        <div className='flex flex-col items-center pt-[3vh] bg-value dark:bg-black dark:text-white tablet:w-[20vw] mobile:w-[36vw] rounded px-[3vw] pb-[1vh]'>
                            <img src={synergy} alt='our value' className='tablet:w-[5vw] desktop:w-[5vw] half:w-[8vw] med:w-[7vw] mobile:w-[6vw] mt-[2vh]'></img>
                            <h1 className='text-center description1 text-center pb-[3vh] mt-[2vh]'>
                                { language === 'en' ? 'Synergy' : 'Sinergi'}
                            </h1>
                            <p className='text-center font-mulish dark:opacity-[70%] tablet:text-[18px] mobile:text-[14px] tablet:leading-[30px] mobile:leading-[20px] font-normal tracking-wide text-center mb-[2vh]'>
                                { language === 'en' ? 'Effective teamwork and respect diversity, equal opportunity, and inclusion.' : 'Kerja tim yang efektif dan menghormati keragaman, kesempatan yang sama, dan inklusi.'}
                            </p>
                        </div>
                    </div>
                </div>
        </div>
    </section>
    <SubFooter/>
        </>
    );
}

export default AboutUs;