import React, { useState } from 'react';

function Captcha({ captcha, handleChange, onSubmit, disabled, language }) {

   return (
    <div className="container">
      <div className="row">
          <div className="">
            
            <div className="flex justify-center items-center tablet:w-[40vw] tablet:h-[20vh] mobile:w-[50vw] mobile:h-[12vh] captch">
              <div className='flex justify-center items-center'>
                <h4 id="captcha" className='flex justify-center items-center font-mulish tablet:text-[22px] mobile:text-[14px] half:text-[15px] med:text-[16px] font-medium tracking-wide pb-[3vh]'>{captcha}</h4>
              </div>
            </div>
            <div className="flex flex-row tablet:w-[40vw] mobile:w-[50vw]">
              <input type="text" id="inputType" className="border-[1px] bg-white dark:bg-dark dark:border-black text-black dark:text-white w-full tablet:h-[7vh] mobile:h-[5vh] px-[1vw] tablet:py-[1vh] rounded" placeholder={language === 'en' ? "Type the Text Here" : 'Ketikan captcha disini' }
                name="username" onChange={handleChange} autoComplete="off"
                />
              <button type="button" id="succesBTN" disabled={disabled} onClick={onSubmit} className="mb-[10vh] tablet:h-[7vh] mobile:h-[5vh] px-[1vw] py-[1vh] bg-[#4493f1] rounded hover:bg-[#123872]"><p className='text-white font-normal font-mulish tablet:text-[18px] mobile:text-[14px] half:text-[15px] med:text-[16px] font-normal tracking-wide text-justify'>
                {language === 'en' ? "Submit" : 'Kirim' }
              </p></button>
            
            </div>
        
           </div>
        </div>
      </div>
    );
}
export default Captcha;