import React from "react";
import { useRef, useEffect } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo1.png";
import logoDark from "../../assets/images/logo-dark.png";
import DarkModeToggle from "../DarkModeToggle";
import { useDispatch, useSelector } from "react-redux";

function NavbarHome(props) {
	const navRef = useRef();
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const dispatch = useDispatch()
    const language = useSelector(state => state.language)

	const showNavbar = () => {
		navRef.current.classList.toggle(
			"responsive_nav"
		);
	};

    const [sticky, setSticky] = React.useState({isSticky: false, offset: 0})
 
    const headerRef = React.useRef(null)

    const handleScroll = () => {
        const offset = window.scrollY
        const isSticky = offset > sticky.offset
        setSticky({isSticky, offset})
    }

    useEffect(() => {
        setSticky({isSticky: false, offset: headerRef.current.offsetTop})
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', () => handleScroll)
        }
        // eslint-disable-next-line
    }, [])

    const activeStyle = 'flex justify-center items-center font-mulish font-normal text-[#4493f1] text-[16px] active:text-[#4483f1] leading-[22.5px]'
    const inactiveStyle = 'flex justify-center items-center font-mulish font-normal text-[#000000] dark:text-white text-[16px] leading-[22.5px]'

    
	return (
        <>
        {/* // main navbar */}
		<section className="mobile:hidden tablet:block">
            <header className={`fixed pl-[5vw] top-0 z-50 ${sticky.isSticky ? 'bg-white shadow-md dark:bg-dark' : 'bg-white dark:bg-dark'}`} ref={headerRef}>
			<div className="flex flex-row justify-between items-center w-[100%] h-[10vh]">
            <div className="dark:hidden">
                <a href="/home" >
                    <img src={logo} alt="logo" className="tablet:w-[30vw] mobile:w-[35vw]"/>
                </a>
            </div>
            <div className="hidden dark:flex">
                <a href="/home" >
                    <img src={logoDark} alt="logo" className="tablet:w-[30vw] mobile:w-[35vw]"/>
                </a>
            </div>
            <nav ref={navRef} className="flex flex-row justify-between dark:bg-dark items-center w-[100%] pl-[4vw]">
            <div className="flex tablet:flex-row mobile:flex-col justify-between items-center w-[100%]">
            <NavLink to='/about-us'
                 className={({ isActive }) => isActive ? activeStyle : inactiveStyle}
                ><p className="hover:text-[#4493f1] tablet:pb-[0vh] mobile:pb-[4vh] mr-[0.2vw]">
                    {language === 'en' ? 'About Us' : 'Tentang Kami' }
                </p></NavLink>
            <NavLink to='/capabilities'
                className={({ isActive }) => isActive ? activeStyle : inactiveStyle}
                ><p className="hover:text-[#4493f1] tablet:pb-[0vh] mobile:pb-[4vh] mr-[0.2vw]">
                    { language === 'en' ? 'Capabilities' : 'Kemampuan' }
                </p></NavLink>
            <NavLink to='/people'
                className={({ isActive }) => isActive ? activeStyle : inactiveStyle}
                ><p className="hover:text-[#4493f1] tablet:pb-[0vh] mobile:pb-[4vh] mr-[0.2vw]">
                    { language === 'en' ? 'People' : 'Advokat' }
                </p></NavLink>
            <NavLink to='/news-and-insights'
                className={({ isActive }) => isActive ? activeStyle : inactiveStyle}
                ><p className="hover:text-[#4493f1] tablet:pb-[0vh] mobile:pb-[4vh] mr-[0.2vw]">
                    { language === 'en' ? 'News and Insights' : 'Berita & Informasi' }
                </p></NavLink>
            <NavLink to='/career'
                className={({ isActive }) => isActive ? activeStyle : inactiveStyle}
                ><p className="hover:text-[#4493f1] tablet:pb-[0vh] mobile:pb-[4vh]">
                    { language === 'en' ? 'Career' : 'Karir' }
                </p></NavLink>
            </div>
				<button
					className="nav-btn nav-close-btn"
					onClick={showNavbar}>
					<FaTimes className="text-black dark:text-white"/>
				</button>
			</nav>
            <div>
            <form className="searchBox flex flex-row ml-[2w]" onSubmit={e => {
                e.preventDefault()
                if(pathname === '/search') {
                    props.setSearchData(e.target[0].value);
                } else {
                    navigate('/search', {state: e.target[0].value})
                }
            }}>
                {/* Search */}
                <div className="flex flex-row justify-end items-center">
                    <div className="flex items-start"><FiSearch className="icon text-black dark:text-white" /></div>
                    <input type="text" placeholder="Search" className="dark:text-white"/>
                    <div>
                    </div>
                </div>
            </form>
            </div>
            {/* <button
                onClick={() => {
                    localStorage.setItem('lang', language === 'id' ? 'en' : 'id');
                    dispatch({ type: 'LANGUAGE', data: language === 'id' ? 'en' : 'id' })
                }}
                className="font-mulish font-normal text-[#000000] text-[16px] leading-[22.5px] tracking-wide bg-[#f2f2f2] w-[7vw] py-[1.5vh] rounded hover:bg-[#e4e4e4]"
            >
                {String(localStorage.getItem('lang') || language).toUpperCase()}
            </button> */}
            </div>
            <div className="ml-[1vw]">
                <DarkModeToggle />
            </div>
            
			<button
				className="nav-btn dark:bg-dark ml-[1vw]"
				onClick={showNavbar}>
				<FaBars className="text-black dark:text-white"/>
			</button>
		</header>
        </section>

        {/* // mobile navbar */}
        <section className="tablet:hidden">
            <header className={`fixed pl-[5vw] top-0 z-50 ${sticky.isSticky ? 'bg-white shadow-md dark:bg-dark' : 'bg-white dark:bg-dark'}`} ref={headerRef}>
			<div className="flex justify-between items-center w-[100%] h-[10vh]">
            <div className="">
            <div className="dark:hidden">
                <a href="/home" >
                    <img src={logo} alt="logo" className="tablet:w-[30vw] mobile:w-[35vw]"/>
                </a>
            </div>
            <div className="hidden dark:flex">
                <a href="/home" >
                    <img src={logoDark} alt="logo" className="tablet:w-[30vw] mobile:w-[35vw]"/>
                </a>
            </div>
            </div>
            <div>
            <form className="searchBoxMobile flex flex-row" onSubmit={e => {
                e.preventDefault()
                if(pathname === '/search') {
                    props.setSearchData(e.target[0].value);
                } else {
                    navigate('/search', {state: e.target[0].value})
                }
            }}>
                {/* Search */}
                <div className="flex flex-row justify-end">
                    <div className="flex">
                        <FiSearch className="icon text-black dark:text-white" />
                    <input type="text" placeholder="Search" className="dark:text-white"/>
                    </div>
                    <div>
                    </div>
                </div>
            </form>
            </div>

            {/* <button
                onClick={() => {
                    localStorage.setItem('lang', language === 'id' ? 'en' : 'id');
                    dispatch({ type: 'LANGUAGE', data: language === 'id' ? 'en' : 'id' })
                }}
                className="font-mulish font-normal text-[#000000] text-[16px] leading-[22.5px] tracking-wide bg-[#f2f2f2] tablet:w-[7vw] mobile:w-[10vw] py-[1vh] mr-[3vw] rounded hover:bg-[#e4e4e4]"
            >
                {String(localStorage.getItem('lang') || language).toUpperCase()}
            </button> */}

            <nav ref={navRef} className="flex flex-col dark:bg-dark w-[100%] h-[100%] pl-[4vw]">
            <div className="flex tablet:flex-row mobile:flex-col justify-between items-center w-[100%]">
            <NavLink to='/about-us'
                 className={({ isActive }) => isActive ? activeStyle : inactiveStyle}
                ><p className="hover:text-[#4493f1] tablet:pb-[0vh] mobile:pb-[4vh] mr-[0.2vw]">
                    {language === 'en' ? 'About Us' : 'Tentang Kami' }
                </p></NavLink>
                <NavLink to='/capabilities'
                    className={({ isActive }) => isActive ? activeStyle : inactiveStyle}
                ><p className="hover:text-[#4493f1] tablet:pb-[0vh] mobile:pb-[4vh] mr-[0.2vw]">
                    { language === 'en' ? 'Capabilities' : 'Kemampuan' }
                </p></NavLink>
                <NavLink to='/people'
                    className={({ isActive }) => isActive ? activeStyle : inactiveStyle}
                ><p className="hover:text-[#4493f1] tablet:pb-[0vh] mobile:pb-[4vh] mr-[0.2vw]">
                    { language === 'en' ? 'People' : 'Pengacara' }
                </p></NavLink>
                <NavLink to='/news-and-insights'
                    className={({ isActive }) => isActive ? activeStyle : inactiveStyle}
                ><p className="hover:text-[#4493f1] tablet:pb-[0vh] mobile:pb-[4vh] mr-[0.2vw]">
                    { language === 'en' ? 'News and Insights' : 'Berita & Wawasan' }
                </p></NavLink>
                <NavLink to='/career'
                    className={({ isActive }) => isActive ? activeStyle : inactiveStyle}
                ><p className="hover:text-[#4493f1] tablet:pb-[0vh] mobile:pb-[4vh]">
                    { language === 'en' ? 'Career' : 'Karir' }
                </p></NavLink>
                
                <div className="ml-[1vw] pt-[3vh]">
                    <DarkModeToggle />
                </div>
            </div>

				<button
					className="nav-btn nav-close-btn"
					onClick={showNavbar}>
					<FaTimes className="text-black dark:text-white"/>
				</button>
			</nav>
            </div>
            
			<button
				className="nav-btn dark:bg-dark"
				onClick={showNavbar}>
				<FaBars className="text-black dark:text-white"/>
			</button>
		</header>
        </section>
    </>
	);
}

export default NavbarHome;