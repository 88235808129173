import axios from "axios";

export const getCareerPage = (token) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'GET',
            url: `${process.env.REACT_APP_BASE_URL}/api/pages`,
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                'filters[name][$contains]': 'career',
                populate: '*'
            }
        })
        .then(res => {
            const data = res.data.data[0]?.attributes;
            resolve({
                ...data,
                image: `${process.env.REACT_APP_BASE_URL}${data?.media?.data[0]?.attributes?.formats?.medium?.url}`
            })
        })
        .catch(err => {
            reject('Failed to Get Career Page!')
        })
    })
}

export const getInternshipInfo = (language = 'id') => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'GET',
            url: `${process.env.REACT_APP_BASE_URL}/api/posts`,
            params: {
                'filters[title][$contains]': 'internship',
                'populate[0]': 'pages'
            }
        })
        .then(res => {
            const data = {
                title: language == 'en'
                    ? res.data.data[0].attributes.title
                    : res.data.data[0].attributes.title_bahasa,
                paragraph: []
            };

            if(language === 'en') {
                res.data.data[0].attributes.content?.split('\n').filter(item => item).map((item, index) => {
                    if(index === 0) {
                        data.topic = item.replaceAll('**', '');
                    } else {
                        data.paragraph.push(item)
                    }
                });
            } else {
                res.data.data[0].attributes.content_bahasa?.split('\n').filter(item => item).map((item, index) => {
                    if(res.data.data[0].attributes.content_bahasa?.split('\n').length > 1) {
                        if(index === 0) {
                            data.topic = item.replaceAll('**', '');
                        } else {
                            data.paragraph.push(item)
                        }
                    } else {
                        data.topic = ''
                        data.paragraph.push(res.data.data[0].attributes.content_bahasa)
                    }
                });
            }
            
            resolve(data)
        })
        .catch(err => {
            reject('Failed to Get Career Page!')
        })
    })
}

export const getQualifiedLawyersInfo = (language = 'id') => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'GET',
            url: `${process.env.REACT_APP_BASE_URL}/api/posts`,
            params: {
                'filters[title][$contains]': 'associate',
                'populate[0]': 'pages'
            }
        })
        .then(res => {
            const data = {
                title: language == 'en'
                    ? res.data.data[0].attributes.title
                    : res.data.data[0].attributes.title_bahasa,
                paragraph: []
            };

            if(language == 'en') {
                res.data.data[0].attributes.content?.split('\n').filter(item => item).map((item, index) => {
                    if(index == 0) {
                        data.topic = item.replaceAll('**', '');
                    } else {
                        data.paragraph.push(item)
                    }
                });
            } else {
                res.data.data[0].attributes.content_bahasa?.split('\n').filter(item => item).map((item, index) => {
                    if(res.data.data[0].attributes.content_bahasa?.split('\n').length > 1) {
                        if(index == 0) {
                            data.topic = item.replaceAll('**', '');
                        } else {
                            data.paragraph.push(item)
                        }
                    } else {
                        data.topic = ''
                        data.paragraph.push(res.data.data[0].attributes.content_bahasa)
                    }
                });
            }

            resolve(data)
        })
        .catch(err => {
            reject('Failed to Get Career Page!')
        })
    })
}

export const internshipForm = (data) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_BASE_URL}/api/auth/local`,
            data: {
                identifier: process.env.REACT_APP_USER,
                password: process.env.REACT_APP_USER_PASSWORD
            }
        })
        .then(response => {
            axios({
                method: 'POST',
                url: `${process.env.REACT_APP_BASE_URL}/api/internships`,
                headers: {
                    Authorization: `Bearer ${response.data.jwt}`
                },
                data: {
                    data
                }
            })
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject('Failed to Send Internship Form!')
            })
        })
        .catch(err => {
            reject('Failed to Send Internship Form!')
        })
        
    })
}

export const qualifiedLawyersForm = (data) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_BASE_URL}/api/auth/local`,
            data: {
                identifier: process.env.REACT_APP_USER,
                password: process.env.REACT_APP_USER_PASSWORD
            }
        })
        .then(response => {
            axios({
                method: 'POST',
                url: `${process.env.REACT_APP_BASE_URL}/api/lawyers`,
                headers: {
                    Authorization: `Bearer ${response.data.jwt}`
                },
                data: {
                    data
                }
            })
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject('Failed to Send Qualified Lawyers Form!')
            })
        })
        .catch(err => {
            reject('Failed to Send Qualified Lawyers Form!')
        })
        
    })
}