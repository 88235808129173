import React from "react"
import logo from "../../assets/images/logo1.png"

const Navbar = () => {
    return (
        <section className="flex flex-row justify-between items-center bg-white shadow-md h-[10vh] px-[5vw] py-[2vh]">
            <div className="">
                <a href="/home" >
                    <img src={logo} alt="logo" className="tablet:w-[20vw] mobile:w-[35vw]"/>
                </a>
            </div>
        </section>
    );
}

export default Navbar;