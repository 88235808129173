import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import NavbarHome from '../../components/NavbarHome';
import SubFooter from '../../components/SubFooter';
import { useNavigate } from 'react-router-dom';

// API
import { getCapabilitiesPage } from '../../apiService/capabilitiesPage';
import CapabilitiesCard from './components/CapabilitiesCard';

const Practice = () => {
  const navigate = useNavigate();
  const token = useSelector(state => state.token)
  const language = useSelector(state => state.language)

  const[data, setData] = useState([]);
  
    const selectOption = (e) => {
      // navigate(`/capabilities/practice-area/${e.target.value.toLowerCase().replaceAll(' ', '-')}`, {state: e.target.value})
      navigate(`/capabilities/practice-area/${e.target.value}`, {state: e.target.value})
    }

    useEffect(() => {
        window.scrollTo(0,0)
        getCapabilitiesPage(token)
        .then(res => {
          setData(res)
        })
        .catch(err => {})
    }, [])

    return (
        <>
        <NavbarHome />
        <section className='bg-light dark:bg-dark px-[5vw] tablet:pb-[15vh] mobile:pb-[8vh] tablet:pt-[15vh] mobile:pt-[10vh]'>
          { data?.map((item, index) => (
            <CapabilitiesCard key={index} data={item.attributes} language={language} onChange={selectOption} />
          ))}
        </section>
    
        <SubFooter />
        </>
        
    );
}

export default Practice;