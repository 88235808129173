import React, { useEffect, useState } from 'react';
import { IoMailOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import NavbarHome from '../../components/NavbarHome';
import SubFooter from '../../components/SubFooter';
import ClientUpdate from '../../components/ClientUpdate';
import Pagination from '../../components/Pagination';

import { getPerspectivePage, getPerspectivePostByFilter } from '../../apiService/perspectivePage';

const Perspectives = () => {
    const navigate = useNavigate();
    const token = useSelector(state => state.token)
    const language = useSelector(state => state.language)

    const [newPost, setNewPost] = useState({});

    const [filter, setFilter] = useState('all');
    const [filteredData, setFilteredData] = useState([]);

    const [currentPage, setCurrentPage] = useState(1)
    const [postsPerPage, setPostsPerPage] = useState(8)
    const [total, setTotal] = useState(0)

    useEffect(() => {
        window.scrollTo(0,0)
        // getPerspectivePage(token, currentPage, postsPerPage)
        // .then(res => {
        //     setNewPost(res.banner)
        //     setTotal(res?.pagination?.total);
        //     setFilteredData(res.allPosts);
        // })
        // .catch(err => null)
      }, [])

    useEffect(() => {
        setFilteredData([])
        if(filter === 'all') {
            getPerspectivePage(token, currentPage, postsPerPage, language)
            .then(res => {
                setNewPost(res.banner)
                setTotal(res?.pagination?.total);
                setFilteredData(res.allPosts);
            })
            .catch(err => null)
        } else {
            setNewPost({})
            getPerspectivePostByFilter(token, filter, currentPage, postsPerPage, language)
            .then(res => {
                setNewPost(res.banner)
                setTotal(res?.pagination?.total);
                setFilteredData(res.allPosts);
            })
            .catch(err => null)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, currentPage, language]);

    return (
        <>
        <NavbarHome />
        <section className='bg-light dark:bg-dark py-[10vh] px-[5vw]'>
        <div className='flex flex-col justify-between pt-[5vh]'>
            <div className='flex flex-col'>
                <ul className='flex flex-row justify-between'>
                  <h1 className='caption-home1 text-[#4493f1] uppercase'>
                    { language === 'en' ? 'News and Insights': 'Berita & Informasi' }
                  </h1>
                </ul>
                <ul className='flex flex-row justify-between items-center pb-[2vh] text-[#123872]'>
                    <div className='flex flex-row'>
                    <button  
                        onClick={() => { setFilter('all'); setCurrentPage(1) }}
                        className='flex items-center font-mulish dark:text-white tablet:text-[18px] mobile:text-[12px] border-[0.1vw] border-transparent rounded px-[2vw] py-[2vh] hover:bg-[#4493f1] focus:bg-[#4493f1] focus:text-white hover:text-white mr-[1vw] mb-[3vh]'>
                            { language === 'en' ? 'ALL': 'SEMUA' }
                        </button>
                    <button 
                    onClick={() => { setFilter('Client Updates'); setCurrentPage(1) }}
                    className='flex items-center font-mulish dark:text-white tablet:text-[18px] mobile:text-[12px] border-[0.1vw] border-transparent rounded px-[2vw] py-[2vh] hover:bg-[#4493f1] focus:bg-[#4493f1] focus:text-white hover:text-white mr-[1vw] mb-[3vh] uppercase'>
                        { language === 'en' ? 'Client Updates': 'Informasi Terkini' }
                    </button>
                  <button 
                  onClick={() => { setFilter('Published Articles'); setCurrentPage(1) }}
                  className='flex items-center font-mulish dark:text-white tablet:text-[18px] mobile:text-[12px] border-[0.1vw] border-transparent rounded px-[2vw] py-[2vh] hover:bg-[#4493f1] focus:bg-[#4493f1] focus:text-white hover:text-white mr-[1vw] mb-[3vh] uppercase'>
                    { language === 'en' ? 'Published Articles': 'Berita Terkait' }
                  </button>
                    </div>
                    <a href='/mailing-list' className="flex items-center font-mulish dark:text-white tablet:text-[18px] mobile:text-[12px] border-[0.1vw] border-[#4493f1] dark:border-white dark:hover:border-transparent rounded px-[2vw] py-[2vh] focus:bg-[#4493f1] hover:bg-[#4493f1] text-[#4493f1] hover:text-white mr-[1vw] mb-[3vh]">
                        <button className="flex flex-row items-center">
                            <IoMailOutline size={20} className='mr-[0.5vw]'/>{language === 'en' ? 'SUBSCRIBE' : 'LANGGANAN'}
                        </button>
                    </a>
                </ul>
            </div>

            {/* Carousel */}
            <div
                style={{ transform: `translateX(0)`}}
                className="flex items-center w-[100%] tablet:h-[65vh] mobile:h-[40vh] mt-[4vh] group"
            >
                <div style={{backgroundImage: `url(${newPost?.image})`}} className="flex items-center justify-center w-full h-full bg-center bg-cover duration-500 ease">
                    <div className="flex flex-row items-center justity-between">
                    </div>
                    <div className="flex items-center justify-center dark:bg-black dark:bg-opacity-50 bg-white bg-opacity-50 h-full w-full">
                    <div className="flex flex-col items-center justify-center">
                        <p className="font-mulish dark:text-white tablet:text-[25px] mobile:text-[16px] mb-[1.5vh]">{language === 'en' ? newPost?.category : (newPost?.category === 'Client Updates' ? 'Informasi Terkini' : 'Berita Terkait')}</p>
                        <h1 className="flex font-mulish dark:text-white tablet:text-[32px] tablet:leading-[60px] mobile:leading-[25px] tracking-wide mobile:text-[18px] font-bold text-center tablet:w-[90%] mobile:w-[90%] tablet:mb-[5vh] mobile:mb-[2.5vh]">{newPost?.title}</h1>
                        <button
                            // onClick={() => navigate(`/news-and-insights/post/${newPost?.id}`)}
                            onClick={() => navigate(`/news-and-insights/post/${newPost?.title}`)}
                            className='bg-[#636568] dark:bg-[#4493f1] dark:hover:bg-[#123872] hover:bg-[#000] font-mulish tablet:text-[18px] mobile:text-[12px] font-normal tracking-wide uppercase tablet:px-[1vw] mobile:px-[2vw] tablet:py-[1.5vh] mobile:py-[1.2vh] rounded text-white'
                        >
                            {language === 'en' ? 'Learn More' : 'Informasi Lebih Lanjut'}
                        </button>
                    </div>
                    </div>
                </div>
            </div>
            {/* Carousel */}
        </div>

        <ClientUpdate dataItems={filteredData}/>
        
        <div className='flex justify-end items-center pr-[5vw]'>
            <Pagination postsPerPage={postsPerPage} totalPosts={total} setCurrentPage={setCurrentPage}/>
        </div>

        </section>
        <SubFooter/>
        </>
        
    );
}

export default Perspectives;