import axios from "axios";

const getAboutUsPage = (language = 'id') => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'GET',
            url: `${process.env.REACT_APP_BASE_URL}/api/pages`,
            params: {
                'filters[name][$contains]': 'about',
                populate: '*'
            }
        })
        .then(res => {
            let image;

            try {
                image = `${process.env.REACT_APP_BASE_URL}${res.data.data[0]?.attributes?.media?.data[0]?.attributes?.formats?.large?.url}`
            } catch (error) {}

            const data = {
                title: language === 'en' ? res.data.data[0]?.attributes?.one_liner : res.data.data[0]?.attributes?.one_liner_bahasa,
                cta: res.data.data[0]?.attributes?.call_to_action,
                image,
                post: {
                    title: language === 'en' ? res.data.data[0]?.attributes?.posts?.data[0]?.attributes?.title : res.data.data[0]?.attributes?.posts?.data[0]?.attributes?.title_bahasa,
                    content: language === 'en' ? res.data.data[0]?.attributes?.posts?.data[0]?.attributes?.content : res.data.data[0]?.attributes?.posts?.data[0]?.attributes?.content_bahasa
                }
            };

            resolve(data)
        })
        .catch(err => {
            reject('Failed to Get About Page!')
        })
    })
}

export default getAboutUsPage;