import axios from "axios";

export const getPerspectivePage = (token, page, limit, language = 'id') => {
    return new Promise(async (resolve, reject) => {
        try {
            const banner = await axios({
                method: 'GET',
                url: `${process.env.REACT_APP_BASE_URL}/api/posts?filters[priority]=1&filters[is_active][$eq]=True&[filters][categories][name][$eq]=Client Updates&[filters][categories][name][$eq]=Published Articles&populate=*&sort=priority%3Aasc`,
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })

            const res = await axios({
                method: 'GET',
                url: `${process.env.REACT_APP_BASE_URL}/api/posts?filters[is_active][$eq]=True&[filters][categories][name][$eq]=Client Updates&[filters][categories][name][$eq]=Published Articles&populate=*&sort=createdAt%3Adesc`,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                params: {
                    'pagination[page]': page,
                    'pagination[pageSize]': limit
                }
            })

            const allPosts = res.data.data.map(item => ({
                id: item.id,
                title: language == 'en'
                    ? item.attributes.title
                    : item.attributes.title_bahasa,
                content: language == 'en'
                    ? item.attributes.content
                    : item.attributes.content_bahasa,
                category: item.attributes.categories.data[0].attributes.name,
                publishedAt: item.attributes.publishedAt,
                image: `${process.env.REACT_APP_BASE_URL}${item.attributes.media?.data[0]?.attributes?.url}`
            }))

            resolve({
                banner: {
                    id: banner.data.data[0].id,
                    title: language == 'en'
                        ? banner.data.data[0].attributes?.title
                        : banner.data.data[0].attributes?.title_bahasa,
                    content: language == 'en'
                        ? banner.data.data[0].attributes?.content
                        : banner.data.data[0].attributes?.content_bahasa,
                    category: banner.data.data[0].attributes.categories.data[0].attributes.name,
                    publishedAt: banner.data.data[0].attributes.publishedAt,
                    image: `${process.env.REACT_APP_BASE_URL}${banner.data.data[0].attributes.media?.data[0]?.attributes?.url}`
                },
                allPosts,
                pagination: res.data?.meta?.pagination,
            })

        } catch (error) {
            reject('Failed to Get Perspective Page!')    
        }
    })
}

export const getPerspectivePostByFilter = (token, category, page, limit, language = 'id') => {
    return new Promise(async (resolve, reject) => {
        try {
            const banner = await axios({
                method: 'GET',
                url: `${process.env.REACT_APP_BASE_URL}/api/posts?filters[is_active][$eq]=True&[filters][categories][name][$eq]=${category}&populate=*&sort=priority%3Aasc`,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                params: {
                    'pagination[pageSize]': limit
                }
            })

            const res = await axios({
                method: 'GET',
                url: `${process.env.REACT_APP_BASE_URL}/api/posts?sort=createdAt%3Adesc`,
                headers: {
                    Authorization: `Bearer ${token}`
                },
                params: {
                    populate: '*',
                    '[filters][categories][name][$eq]': category,
                    'filters[is_active][$eq]': 'True',
                    'pagination[page]': page,
                    'pagination[pageSize]': limit
                }
            })

            const allPosts = res.data.data.filter(item => item.attributes.categories.data.length)
            .map(item => ({
                id: item.id,
                title: language == 'en'
                    ? item.attributes.title
                    : item.attributes.title_bahasa,
                content: language == 'en'
                    ? item.attributes.content
                    : item.attributes.content_bahasa,
                category: item.attributes.categories.data[0].attributes.name,
                publishedAt: item.attributes.publishedAt,
                image: `${process.env.REACT_APP_BASE_URL}${item.attributes.media?.data[0]?.attributes?.url}`
            }))

            const data = {
                banner: {
                    id: banner.data.data[0]?.id,
                    title: language == 'en'
                        ? banner.data.data[0].attributes?.title
                        : banner.data.data[0].attributes?.title_bahasa,
                    content: language == 'en'
                        ? banner.data.data[0].attributes?.content
                        : banner.data.data[0].attributes?.content_bahasa,
                    category: banner.data.data[0]?.attributes?.categories.data[0].attributes.name,
                    publishedAt: banner.data.data[0]?.attributes?.publishedAt,
                    image: `${process.env.REACT_APP_BASE_URL}${banner.data.data[0]?.attributes?.media?.data[0]?.attributes?.url}`
                },
                allPosts,
                pagination: res.data?.meta?.pagination,
            }

            resolve(data)

        } catch (error) {
            reject('Failed to Get Perspective Page!')   
        }
    })
}

export const getPostDetail = (postId, token, language = 'id') => {
    return new Promise(async (resolve, reject) => {
        try {

            let response = await axios({
                method: 'GET',
                url: `${process.env.REACT_APP_BASE_URL}/api/posts?[filters][title][$contains]=${postId}`
            })

            if(response.data?.data?.length > 1) {
                response.data.data = response.data.data.filter(item => item.attributes.title === postId)
            }

            const res = await axios({
                method: 'GET',
                url: `${process.env.REACT_APP_BASE_URL}/api/posts/${response.data.data[0].id}`,
                params: {
                    populate: '*'
                }
            })

            const partners = new Array();

            for(let i = 0; i < res.data.data?.attributes?.partners?.data?.length; i++) {
                const response = await axios({
                    method: 'GET',
                    url: `${process.env.REACT_APP_BASE_URL}/api/partners/${res.data.data?.attributes?.partners?.data[i]?.id}`,
                    params: {
                        populate: 'avatar'
                    },
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                })

                partners.push({
                    id: response.data?.data?.id,
                    full_name: response.data?.data?.attributes?.full_name,
                    email: response.data?.data?.attributes?.email,
                    type: response.data?.data?.attributes?.type,
                    url: `${process.env.REACT_APP_BASE_URL}${response.data?.data?.attributes?.avatar.data.attributes.formats.small.url}`
                })
            }

            const newContent = (language === 'en'
                ? res.data.data?.attributes?.content?.split(/\n\n|\n/)
                : res.data.data?.attributes?.content_bahasa?.split(/\n\n|\n/)
            )?.map(item => {
                // Link
                if(item.includes('http')) {
                    let link;
                    
                    if(!item.startsWith('http')) {
                        // const regex = /\[(.*?)\]\((.*?)\)/;
                        const regex = /https?:\/\/[^\s]+/;
                        const match = regex.exec(item);
                        
                        link = match.find(text => text.startsWith('http'))
                    }

                    if(link) {
                        let text = `<p>${item.replace(link, `<a href='${link}' target='_blank' style="color: blue;">${link}</a>`)}</p>`;
                        return text
                    } else {
                        return `<a href='${link || item}' target='_blank' style="color: blue;">${item}</a>`
                    }
                }
                
                // Image
                if(item.includes('![')) {
                    const str = item.match(/\((.*?)\)/)
                    
                    if (str && str.length > 1) {
                        const linkText = str[1];
                        return `img-${process.env.REACT_APP_BASE_URL}${linkText}`
                    }

                    return item
                }

                return item
            })

            const data = {
                image: `${process.env.REACT_APP_BASE_URL}${res.data.data?.attributes?.media?.data[0]?.attributes?.url}`,
                title: language == 'en'
                    ? res.data.data?.attributes?.title
                    : res.data.data?.attributes?.title_bahasa,
                content: newContent,
                category: res.data.data?.attributes?.categories.data[0].attributes.name,
                date: res.data.data?.attributes?.updatedAt,
                partners,
                article_pdf: res.data.data?.attributes?.article_pdf?.data?.attributes?.url
            }

            resolve(data)

        } catch (error) {
            reject('Failed to Get Perspective Page!')
        }
    })
}