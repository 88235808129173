import axios from "axios";

export const getPeoplePage = (token, type = '', practiceArea = '', page, limit) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'GET',
            url: `${process.env.REACT_APP_BASE_URL}/api/partners?${practiceArea && `[filters][practice_areas][name][$contains]=${practiceArea}`}${type && `&filters[type][$contains]=${type}`}&sort=priority%3Aasc`,
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                populate: '*',
                'pagination[page]': page,
                'pagination[pageSize]': limit
            }
        })
        .then(res => {
            const data = {
                data: [],
                pagination: res.data?.meta?.pagination,
            }

            res.data.data.map(item => {
                data.data.push({
                    id: item.id,
                    name: item.attributes.full_name,
                    phone: item.attributes.phone_number,
                    email: item.attributes.email,
                    role: item.attributes.group_label,
                    image: `${process.env.REACT_APP_BASE_URL}${item.attributes.avatar?.data?.attributes?.formats?.medium?.url}`,
                    practice_areas: item.attributes.practice_areas
                })
            });

            resolve(data)
        })
        .catch(err => {
            reject('Failed to Get People Page!')
        })
    })
}

export const getAllPracticeAreas = (token) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'GET',
            url: `${process.env.REACT_APP_BASE_URL}/api/practice-areas`,
            headers: {
                Authorization: `Bearer ${token}`
            },
        })
        .then(res => {
            const data = []

            res.data.data.map(item => {
                data.push({ 
                    id: item.id,
                    name: item.attributes.name,
                    name_bahasa: item.attributes.name_bahasa
                })
            });

            resolve(data)
        })
        .catch(err => {
            reject('Failed to Get Practice Areas!')
        })
    })
}

export const getPeopleDetail = (peopleId, token, language = 'id') => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'GET',
            url: `${process.env.REACT_APP_BASE_URL}/api/partners?[filters][full_name][$contains]=${peopleId}`,
        })
        .then(res => {
            axios({
                method: 'GET',
                // url: `${process.env.REACT_APP_BASE_URL}/api/partners/${peopleId}`,
                url: `${process.env.REACT_APP_BASE_URL}/api/partners/${res.data.data[0].id}`,
                params: {
                    populate: '*'
                }
            })
            .then(res => {
                // BEGIN:VCARD
                // VERSION:3.0
                // N:Soemadipradja ;Rahmat
                // FN:Rahmat Soemadipradja 
                // ORG:Soemadipradja & Taher
                // ADR:;;Jl. Jenderal Sudirman No. 28;Jakarta Pusat;Jakarta;10210;Indonesia
                // TEL;CELL:+62 811 9337 234
                // EMAIL:rahmat_s@soemath.com
                // URL:https://www.soemath.com
                // END:VCARD
    
                const fullName = res.data.data?.attributes?.full_name.split(' ').reverse();
    
                const vcardContent = 'BEGIN:VCARD\n' +
                                    'VERSION:3.0\n' +
                                    `N:${fullName[0]} ;${fullName[fullName.length - 1]}\n` +
                                    `FN:${res.data.data?.attributes?.full_name}\n` +
                                    'ORG:Soemadipradja & Taher\n' +
                                    // 'ADR:;;Jl. Jenderal Sudirman No. 28;Jakarta Pusat;Jakarta;10210;Indonesia\n' +
                                    `TEL;CELL:${res.data.data?.attributes?.phone_number}\n` +
                                    `EMAIL:${res.data.data?.attributes?.email}\n` +
                                    'URL:https://www.soemath.com\n' +
                                    'END:VCARD';
    
                const encodedVCard = encodeURIComponent(vcardContent);
    
                const newPublications = language == 'en'
                    ? res.data.data?.attributes?.publication?.replaceAll('\n', '').split('-').filter(item => item).map(item => {
                        if(item.match(/https?:\/\/[^\s]+/) || item.match(/http?:\/\/[^\s]+/)) {
                            const str = item.match(/https?:\/\/[^\s]+/) || item.match(/http?:\/\/[^\s]+/)
                            const linkText = str[0].slice(0, str[0].indexOf(']'))
    
                            return item.replace(`[${str[0].slice(0, str[0].indexOf(']'))}]`, `(<a href='${linkText}' target='_blank' style="color: blue;">${linkText}</a>)`)
                        } else {
                            return item
                        }
                    })
                    : res.data.data?.attributes?.publication_bahasa?.replaceAll('\n', '').split('-').filter(item => item).map(item => {
                        if(item.match(/https?:\/\/[^\s]+/) || item.match(/http?:\/\/[^\s]+/)) {
                            const str = item.match(/https?:\/\/[^\s]+/) || item.match(/http?:\/\/[^\s]+/)
                            const linkText = str[0].slice(0, str[0].indexOf(']'))
    
                            return item.replace(`[${str[0].slice(0, str[0].indexOf(']'))}]`, `(<a href='${linkText}' target='_blank' style="color: blue;">${linkText}</a>)`)
                        } else {
                            return item
                        }
                    })
    
                const data = {
                    image: `${process.env.REACT_APP_BASE_URL}${res.data.data?.attributes.avatar.data.attributes?.formats?.medium?.url}`,
                    name: res.data.data?.attributes?.full_name,
                    type: res.data.data?.attributes?.group_label,
                    email: res.data.data?.attributes?.email,
                    resume: `${process.env.REACT_APP_BASE_URL}${res.data.data?.attributes?.resume?.data?.attributes?.url}`,
                    language: res.data.data?.attributes?.language,
                    practice_area_and_industry: res.data.data?.attributes?.practice_areas?.data?.map(item => language == 'en' ? item.attributes.name : item.attributes.name_bahasa),
                    linkedin: res.data.data?.attributes?.LinkedIn,
                    vcardContent: encodedVCard,
                    publications: newPublications,
                    description: language == 'en'
                        ? res.data.data?.attributes?.profile?.split('\n').filter(item => item)
                        : res.data.data?.attributes?.profile_bahasa?.split('\n').filter(item => item),
                    qualifications: language == 'en'
                        ? res.data.data?.attributes?.qualifications?.replaceAll('\n', '').split('-').filter(item => item)
                        : res.data.data?.attributes?.qualifications_bahasa?.replaceAll('\n', '').split('-').filter(item => item),
                    workHighlights: language == 'en'
                        ? res.data.data?.attributes?.work_highlights?.replaceAll('\n', '').split('-').filter(item => item)
                        : res.data.data?.attributes?.work_highlights_bahasa?.replaceAll('\n', '').split('-').filter(item => item),
                    awards: language == 'en'
                        ? res.data.data?.attributes?.awards?.replaceAll('\n', '').split('-').filter(item => item)
                        : res.data.data?.attributes?.awards_bahasa?.replaceAll('\n', '').split('-').filter(item => item)
                }
                resolve(data)
            })  
        })
        .catch(err => {
            reject('Failed to Get People Detail!')
        })
    })
}