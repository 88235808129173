import React, { useEffect, useState } from 'react';

import NavbarHome from '../../components/NavbarHome';
import CardPathways from '../../components/CardPathways';
import SubFooter from '../../components/SubFooter';

import { useSelector } from 'react-redux';
import { getCareerPage } from '../../apiService/careerPage';

const Pathways = () => {
    const token = useSelector(state => state.token)
    const language = useSelector(state => state.language)

    const [data, setData] = useState({});

    useEffect(() => {
      window.scrollTo(0,0)
      getCareerPage(token)
      .then(res => {
        setData(res)
      })
      .catch(err => null)
    },[])

    return (
        <>
        <NavbarHome />
        <section className='bg-light dark:bg-dark py-[10vh]'>
        <div className='flex flex-row pl-[5vw] justify-between'>
            <div className='flex flex-col'>
              <ul className='w-[95%]'>
                <h1 className='caption-home1 text-[#4493f1] uppercase'>{language === 'en' ? data?.name : data?.name_bahasa}</h1>
              </ul>
            </div>
        </div>
        <div className='flex flex-row justify-center'>
        <div className='justify-center items-center px-[5vw]'>
            <img src={data?.image} alt='client update' className='relative w-[100vw]'></img>
        </div>
        
        </div>
        <div className='pl-[5vw]'>
            <h1 className='caption-pathways dark:text-white py-[5vh]'>{language === 'en' ? data?.call_to_action : data?.call_to_action_bahasa || 'Bekerja dengan kami'}</h1>
            <h2 className='font-mulish tablet:text-[20px] mobile:text-[16px] tablet:leading-[30px] mobile:leading-[25px] font-bold tracking-wide text-justify text-[#4493f1] pb-[3vh]'>{language === 'en' ? 'Careers & Opportunities' : 'Karir & Peluang'}</h2>
            <div className='w-[90%]'>
              <p className='font-mulish dark:text-white tablet:text-[18px] med:text-[16px] mobile:text-[14px] tablet:leading-[30px] mobile:leading-[25px] font-normal tracking-wide text-justify pb-[3vh]'>{language == 'en' ? data?.one_liner : data?.one_liner_bahasa}</p>
            </div>
        </div>

        <section id="our-platforms" className='px-[5vw] mt-[2vh] justify-center'>
        <div className='grid tablet:grid-cols-2 half:grid-cols-1 med:grid-cols-2 mobile:grid-cols-1 grid-flow-row gap-4'>
        
          {data && data?.posts?.data?.map((item, index) => (
            <div key={index}>
                <CardPathways
                  title={language == 'en' ? item.attributes.title : item.attributes.title_bahasa}
                  description={language == 'en' ? item.attributes.meta : item.attributes.meta_bahasa}
                  style='' 
                  onClick={() => window.location.href = `/career/${item.attributes.title.toLowerCase().includes('internship') ? 'internship' : 'associate'}`}
                />
            </div>
          ))}
          <div>
          </div>
        </div>

      </section>
    </section>
    <SubFooter/>
        </>
        
    );
}

export default Pathways;