import React from "react";
import { Link } from "react-router-dom";

const Mailto = ({ mailto, label }) => {
    return (
        <div className="mt-[2vh]">
            <Link className="flex items-center justify-center tablet:w-[10vw] mobile:w-[20vw] bg-[#4493f1] border-[1px] text-white border-transparent py-[1vh] tablet:text-[14px] half:text-[12px] mobile:text-[12px] font-mulish font-medium hover:bg-[#123872] cursor-pointer rounded"
            to='#'
            onClick={(e) => {
                window.location.href = mailto;
                e.preventDefault();
            }}
        >
            {label}
        </Link>
        </div>
    );
};

export default Mailto;