import React from 'react';
import { useState, useEffect } from 'react';
import { BsFillSunFill } from 'react-icons/bs';
import { BsFillMoonFill } from 'react-icons/bs';
import { FaDesktop } from 'react-icons/fa';

const DarkModeToggle = () => {
    const [theme, setTheme] = useState(
        localStorage.getItem('theme') ? localStorage.getItem('theme') : 'system'
    );
    const element = document.documentElement;
    const darkMode = window.matchMedia('(prefers-color-scheme: dark)');

    const options = [
        {
            icon: <BsFillSunFill/>,
            name: 'light',
        },
        {
            icon: <BsFillMoonFill/>,
            name: 'dark',
        },
        {
            icon: <FaDesktop/>,
            name: 'system',
        }
    ]

    function onWindowMatch(){
        if (localStorage.theme === 'dark' || (!('theme' in localStorage) && darkMode.matches)) {
            element.classList.add('dark');
        } else {
            element.classList.remove('dark');
        }
    }

    useEffect(() => {
        switch (theme) {
            case 'dark':
                element.classList.add('dark');
                localStorage.setItem('theme', 'dark');
                break;
            case 'light':
                element.classList.remove('dark');
                localStorage.setItem('theme', 'light');
                break;
            case 'system':
                localStorage.removeItem('theme');
                onWindowMatch();
                break;
        }
    }, [theme]);

    darkMode.addEventListener('change', (e) => {
        if (!('theme' in localStorage)) {
            if (e.matches) {
                element.classList.add('dark');
            } else {
                element.classList.remove('dark');
            }
        }
    });
    
    return (
        <div className="flex flex-row justify-center items-center bg-[#f2f2f2] tablet:px-[0.9vw] half:px-[1.5vw] med:px-[1.2vw] mobile:px-[2.5vw] py-[1.2vh] rounded duration-1000">
            {
                options.map((option) => (
                    <button key={option.name} onClick={() => setTheme(option.name)} 
                    className={`text-xl m-1 px-2 ${theme === option.name && 'text-[#4483f1]'}` }>
                        {option.icon}
                    </button>
                ))
            }
        </div>
    );
}

export default DarkModeToggle;