import { createStore } from 'redux';

const initialState = {
    token: '',
    // language: localStorage.getItem('lang') || 'id'
    language: 'en'
};
  
const rootReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'TOKEN':
            return { ...state, token: action.data }
        case 'LANGUAGE':
            return { ...state, language: action.data }
        default:
            return state;
    }
};

const store = createStore(rootReducer);

export default store;
