import React from "react";
import { BsTelephoneFill} from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import linkedin from "../../assets/images/linkedin.svg";
import instagram from "../../assets/images/instagram.svg";
import footer from "../../assets/images/footer.png";
import footerDark from "../../assets/images/logo-dark.png";
import { useSelector } from "react-redux";

const SubFooter = () => {
    const language = useSelector(state => state.language)

    return (
        <section className="flex flex-row justify-center items-start bg-white dark:bg-black w-[100%] h-max pb-[5vh] px-[5vw] pt-[10vh]">
            <div className="flex items-start justify-between desktop:w-[60%] tablet:w-[80%] half:w-[75%] med:w-[90%] mobile:w-[70%]">
            <div className="grid tablet:grid-cols-3 desktop:grid-cols-3 med:grid-cols-3 half:grid-cols-2 mobile:grid-cols-2 grid-flow-row">
            <div className="flex flex-col dark:text-white font-inter tablet:text-[14px] mobile:text-[12px] tablet:leading-[30px] mobile:leading-[25px] font-medium">
                <a href="/about-us" className="hover:underline mb-[1vh]">
                    {language === 'en' ? 'About Us' : 'Tentang Kami' }
                </a>
                <a href="/capabilities" className="hover:underline mb-[1vh]">
                    { language === 'en' ? 'Capabilities' : 'Kemampuan' }
                </a>
                <a href="/people" className="hover:underline mb-[1vh]">
                    { language === 'en' ? 'People' : 'Advokat' }
                </a>
                <a href="/news-and-insights" className="hover:underline mb-[1vh]">
                    { language === 'en' ? 'News and Insights' : 'Berita & Informasi' }
                </a>
                <a href="/career" className="hover:underline mb-[1vh]">
                    { language === 'en' ? 'Career' : 'Karir' }
                </a>
                {/* <a href="# " className="border-2 border-[#4493f1] my-[3vh] px-[2.4vw] w-max py-[1vh] rounded">
                    <button className="font-inter tablet:text-[14px] mobile:text-[12px] tablet:leading-[30px] mobile:leading-[25px] font-normal text-[#4493f1]">
                        SUBSCRIBE
                    </button>
                </a> */}
            </div>
            <div className="font-inter dark:text-white tablet:text-[14px] mobile:text-[12px] tablet:leading-[30px] mobile:leading-[25px] font-normal">
                <p className="font-bold">Meet us</p>
                <p className="">Wisma GKBI Level 9,</p>
                <p className="mb-[1vh] w-[70%]">Jl. Jenderal Sudirman No.28, Jakarta 10210 - Indonesia</p>
                <p className="font-bold">Contact us</p>
                <p className="mb-[1vh] flex flex-row items-center"><BsTelephoneFill size={15} className='mr-[1vw]'/>(62-21) 50 999 879</p>
                <p className="mb-[1vh] flex flex-row items-center">
                    <MdEmail size={15} className='mr-[1vw]'/>
                    <a href="mailto:center@soemath.com" className="hover:underline">center@soemath.com</a>
                </p>
            </div>
            
            <div className="flex flex-col">
            <p className="hover:underline font-inter dark:text-white tablet:text-[14px] mobile:text-[12px] tablet:leading-[30px] mobile:leading-[25px] font-normal">
                {language === 'en' ? 'Follow us' : 'Ikuti Kami'}
            </p>
            <div className="flex flex-row mt-[2vh]">
            <a href="https://www.linkedin.com/company/soemadipradja-&-taher/">
                <button className="flex items-center justify-center tablet:w-[3.5vw] tablet:h-[3.5vw] mobile:w-[6vw] mobile:h-[6vw] p-2 mr-[1.3vw] rounded-full bg-[#636568] hover:bg-black dark:hover:bg-dark ">
                    <img src={linkedin} alt="linkedin"></img>
                </button>
                </a>
                <a href="https://www.instagram.com/soemadipradja.taher">
                <button className="flex items-center justify-center tablet:w-[3.5vw] tablet:h-[3.5vw] mobile:w-[6vw] mobile:h-[6vw] p-2 rounded-full bg-[#636568] hover:bg-black dark:hover:bg-dark ">
                    <img src={instagram} alt="instagram"></img>
                </button>
                </a>
            </div>
            <img src={footer} alt="footer logo" className='dark:hidden tablet:w-[15vw] med:w-[15vw] mobile:w-[40vw] mt-[4vh]'></img>
            <img src={footerDark} alt="footer logo" className='hidden dark:flex tablet:w-[15vw] med:w-[15vw] mobile:w-[40vw] mt-[4vh]'></img>
            </div>
            </div>
            </div>
            </section>
    );
}

export default SubFooter;