import axios from "axios";

export const mailingForm = (data) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: `${process.env.REACT_APP_BASE_URL}/api/auth/local`,
            data: {
                identifier: process.env.REACT_APP_USER,
                password: process.env.REACT_APP_USER_PASSWORD
            }
        })
        .then(response => {
            axios({
                method: 'POST',
                url: `${process.env.REACT_APP_BASE_URL}/api/subscriptions`,
                headers: {
                    Authorization: `Bearer ${response.data.jwt}`
                },
                data: {
                    data
                }
            })
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                reject('Failed to Send Mailing Form!')
            })
        })
        .catch(err => {
            reject('Failed to Send Mailing Form!')
        })
        
    })
}