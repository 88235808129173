import React, { useEffect, useState } from 'react';
import { RxDoubleArrowRight } from 'react-icons/rx';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Navbar from '../../components/Navbar';
// API
import getMainPage from '../../apiService/mainPage';
import { getAuth } from '../../apiService/auth';


const Main = () => {
    const navigate = useNavigate();

    const dispatch = useDispatch()

    const[data, setData] = useState({
        title: '',
        cta: '',
        image: ''
    });

    useEffect(() => {
        getAuth()
        .then(res => {
            dispatch({type: 'TOKEN', data: res})
        })
        .catch(err => null)

        getMainPage()
        .then(res => {
            setData({
                title: res.title,
                cta: res.cta,
                image: res.image
            })
        })
        .catch(err => null)
    }, []);

    return ( 
        <>
        <Navbar />
        <section className='main tablet:h-[100%] tablet:w-[100%] mobile:w-[100%] mobile:bg-cover mobile:h-[100%] fixed' style={{ backgroundImage: `url(${data?.image})` }}>
            <div className='flex flex-col justify-center items-center'>
                <div className="flex justify-center items-center w-[200%]">
                    <h1 className='font-mulish tablet:text-[50px] tablet:leading-[80px] mobile:text-3xl text-center w-[70%]'>{data?.title}</h1>
                </div>

                <button 
                    className="relative flex items-center justify-center overflow-hidden transition duration-300 ease-out text-black border-[1px] border-black py-[4vh] px-[8vw] text-normal font-mulish font-medium tablet:mt-[5vh] mobile:mt-[7vh] tablet:w-[18vw] mobile:w-[60vw] group"
                    onClick={() => navigate('/home')}
                    >
                    <span className='absolute flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-black group-hover:translate-x-0 ease'>{data?.cta.toUpperCase()}<RxDoubleArrowRight className='ml-[1vh]'/></span>
                    <span className='absolute flex items-center justify-center transition-all duration-300 transform group-hover:translate-x-full ease'>{data?.cta.toUpperCase()}</span>
                </button>
            </div>
        </section>
        </> 
        
    );
}

export default Main;